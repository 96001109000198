.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* .embed-container {
  position: relative;
  padding-bottom: 80%;
  height: 0;
  max-width: 100%;
} */

.embed-container {
  height: 100%;
}
.embed-container iframe,
.embed-container object,
.embed-container iframe {
  /* position: absolute;
  top: 7vh;
  left: 0;
   */
  width: 100%;
  height: 100%;
}
small {
  position: absolute;
  z-index: 40;
  bottom: 0;
  /*margin-bottom: -15px;*/
}

.link {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.6);
  display: flex;
}

.active-link {
  text-decoration: none;
  color: #0477b7;
  border-right: solid 5px #0477b7;
  display: flex;
}
